/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
        <footer className="">
          <div className="flex p-8">

            <div className="flex-auto"><span className="mr-6">© 3DC.io <span className="text-xs hidden sm:inline-block">(2014&mdash;{new Date().getFullYear()})</span></span><a href="https://e-estonia.com" className="hidden md:inline-block">🇪🇪 Made in Estonia</a></div>
            <div className="flex-auto text-right">
              <a href="https://3dc-docs.notion.site/3DC-Docs-423b86f374a34afdb174c184b9342a23" className="mr-6">Docs</a>
              <a href="mailto:info@3dc.io" className="mr-6">Contact Us</a>
              <a href="https://www.iubenda.com/privacy-policy/263130">Privacy</a>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout


